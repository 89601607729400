import "../../component/src/ui/modal/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/modal/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61W62ojNxT+76c4DQQciMzYcbzZyQZCC32BFkopoZwZnbGVaKRZSb6l+N2LpPF4PL5svOSXzbl+37lpnt9oXRgsycK/w7ycfF+/juG/HkBhdBn+AOgKc+HWKSSPPYBND8DprmoYVZve82HA+/MBAZxBZQttyjT+lejo7z4bXd+AzVFSPxl8ndz8IPmpMMk2yPDmDMZJiHkY+qcQHqP6CRi/dOt4PNgwSa4Pi3Uq8Zl8Dx/Ll3w0WQvYpjfYZsmCT6WtcEKrFAqxIu6thLLk6g5kmL9NjZ4rznIttUlhgabPGBaFUMRa6lJzlNEo5HpnQnFadRxqabQOdqhEiRHBbhGG90lpodBmiYbbxz3YA+/BlFYUCLT8vaxjW+pMyGj4AyoS12RYtG/gtWLlHykXF7aSuE6hkLQK7KSYKiYclTaFnJQj48Wvc+tEsWa5Vo6Ua6s+Vrc2sH84OmTFXEpmc0Oknq6cmdPVS0SMnAs1TSGBX0RZaeNQuf0AF1S0ti2Q0x9+cf7UVddnd31iF/N5JnKW0bsg008G49EtJLeQDO4fbmG4PwKsEFJ6jpTuNT8y3Aa+e4FzeAYLxzB3YhHZLAQtWViG0DimsKQdyK/H2FkpOP2qnasX8Ai5L5AMRncWCC19DoVW0osYoI+epl0jLXm/IXlzisbk83p0HgKehPBwaSWbwvEQkjVFTVJ43BMMu4JRFCwFd7PtgjXegeaMxHTmurpYglIodlw/inpcNfocZe7v7mIGDO5G1aqxqJM3BktgMEpqg0yvmBXvYV8zbTgZlulwSAqtnFdR5zAEeYaWbhqrZY1hnISbJL3ZFtdwMHlsHwWfGUbjKuTYHTdDEv38XfYE6AUZiWtWoaL2UxBYzZDrZce30pX3qZW1bWBtkIu5TWE4itD03Hkeu4t0+uidm49Q8IMhCX06mJRG2upa49/udWPYwZ7sTSt13g/MrJZzF0rsdJXCqKZqYlTfmZ96D4r49XBuXmZscjguk6PjMr6PdM1UKJaF87TtSivnNORcasNZZgjfUgg/zEvCxs2EI2YrzCmFyhBbGqxCXxdkCqmXQbDv1Qo/C+EPHteDV9QrGCl+5uWtJ5+Fko/rGtf0ggznTnvZ1OOJTWgjGYSK/661I/OXcLPfZkJyQ6r91sZAR3wNLcjYOAkBKxeG8nrl9JLV+tPcrEOz/3iLEKxNYLsynZ5t56k1tdd7HVjvuLe+GcYdEq/HW9Flk2s5L9VuUUPVnq4KlJauXg4+qoRvD6MFKWe7O77v2l3vrm+k0AL8dhnguvHbCX8uiQuEeGIAFYd+OAXw7Qkmfnziu9Yh1Jm+9ljuhmTbGFKLvsWCGBpCFr4ma93t7mHY9Db/A4utK9YyDgAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var closeButton = '_1cm6qyje';
export var confirmModalContainer = '_1cm6qyjj';
export var confirmModalContent = '_1cm6qyji';
export var heightVar = 'var(--_1cm6qyj1)';
export var minHeightVar = 'var(--_1cm6qyj2)';
export var modalContent = '_1cm6qyjd';
export var modalContentWrapper = '_1cm6qyjc';
export var modalDescription = '_1cm6qyjg';
export var modalFooter = '_1cm6qyjh';
export var modalHeader = '_1cm6qyjf';
export var modalOverlay = '_1cm6qyjb';
export var modalVTScope = '_1cm6qyj3';
export var promptModalContent = '_1cm6qyjk';
export var widthVar = 'var(--_1cm6qyj0)';