import "../../core/src/components/page-list/docs/page-list-item.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/docs/page-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71WYW/bIBD9nl+BKlVqpRI5aZJ1RNv/mKZpInC2WTFYgJt0U//7BNgJdpxk2ap9s8/H3bt3jztPv8+qRdnoIkO/JghxYWtJXwnKJezWE4SYltoQ9ELNHcY0z4UC7GDncG1ERc0rDg733rUEUZSOoOWiDkd9CGxLI9QzQZm3bAV3JUGzLLv1r1SKQmHhoLIEWWfAsdLbnaHKCie0ImhD2XNhdKN4zISy6dw+IF1TJtxrePNH9AuYXOotQaXgHFSA3hjrsXPIaSNdBCAlZiVVBZAuxnryNpnuWSClDxW4GKYesBAc9+WnMb5y6ihmUrBnupHwyZkGvoWQHaRaC+XA9I7NgkdH4mIZSdxow8FgQ7loLEFP0XrUph6VDGLwtITT4HEupAR+H7P5jlHuiez5V6Ca9ktwrOgOt918XGURVCVUZ5tHW1LdPFRX666vBiR14gViMFMIhSXkjiDcltgR0YlFN04KBQQprcKpmnIuVNEee4r5IvXc0KJIqD+gIBvItYHYDK0cKEfQzc26h41urJaNC1mcrglaRghBl7k2FYmPkjr4coeX2W3gJAJJhb7ol9K+pvKOn06I+oIAa0kZlFryRIZHgpnHnG3oPbzkGkQID+l1uMhikE/K5b5bw3yzk/ESo1DFUY4Q9gB7uvzbQGMwH1uYnVzHQB/uM/qMSC6MdZiVQvKo5J76ZquB3MMZSUePmIjhaXDkMXUiKEPL6+56QWt/WwZRF+Nj/USM45Gda+WwFT9hIL5gL/Fjf/Qtx7OFsFwYYFH1TMumUkc4YnZHjVv3mYj09qYOo5Ld+dmAMFot6t19lyd0D6FtKRxgW1MWRsbW0NqbGwsGW5DAXDdJEvirKLqRXXKWBlvd7322rcJWWXYGRgI0LNNDSpBS1FbYAzkWZJ6sxwTuh5NwT25tC0wr3t/bZ2vb2T9k9h/qeLpOo5cVOU6AYFqlZQ+0ngD6+H6AWv6uakii/MEy7FZt70cqAU7/H/Dj/7+R4eEtXiCpUI6rO38pN1fV9KOxTuQeVrvcAwZQ/ELL2ftlidMNh3m8Gv8VfvsNHynTwHcLAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionsCellWrapper = '_1m4huogb';
export var dateCell = '_1m4huoga';
export var dndCell = '_1m4huog2';
export var dragPageItemOverlay = '_1m4huog1';
export var iconCell = '_1m4huog8';
export var operationsCell = '_1m4huogc';
export var root = '_1m4huog0';
export var selectionCell = '_1m4huog4';
export var tagsCell = '_1m4huog9';
export var titleCell = '_1m4huog5';
export var titleCellMain = '_1m4huog6';
export var titleCellPreview = '_1m4huog7';
export var titleIconsWrapper = '_1m4huog3';