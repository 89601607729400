import "../../component/src/ui/notification/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/notification/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA31UwW7bMAy99yu4AAOWgwo7TbtORb9k6EG2aJurIhkSEycb+u+DJKexU7dHkfTje4+kb3fD/q6sHuDfDUDlvEYvvNK0DxIe++PTJTqQ5k5COQsGPhmUEJwhncNHETql3SDhoPwPIVTTkMUxKMp1qlL1a+vd3mpRO+P8uTZzKdaTBgv5TcovJCL4281tfvxMinqlNdlWQvkQeb9nH1NWU+iNOkloDCZVylBrBTHuQg6KwMpzTP3ZB6bmJGpnGS1LCL2qUVTIA6KdQP9K0B1S27GEzTb79XWrGi2jn4CoBDJa/ilGbyKFKwyAnfItWeEzg7KYKVcQDu0UvSyK708Txuf3gsHbqcHVFKSIXyTDWh9nX8a3O6BvTHx2pHU0CYDxyOKSQGOoDxRiauiIUSRbJVg3eNUnVGdZDCO5bZE6mbhS1xanwkB/UUJ5n0NfOlEnAVcl8zXRqWTBibu0g70LxOSsBI9GMR1wvt0S2CsbeuXR8mWpozqL0zZSVtg4j2O7ccNWq3kTVQVn9pyakA3Io+9XZ0u2Q0/81aGNR1kZVb8mJa5XNfFJQnFbbGfMujis8e9wue0oAL7RrneeVdT2/gX+1oqVaIxT/Lxiv8fVSz7FRR3s+rPrAMtDaD4bwv16mUI7HWxqcP9hQ2Y+5Phu/XG1NplJ1jQQd4JqZ1/g3Ckn0i0/r5jYvKvNPx5hsGEJd2lF3/4D0cmuZm0FAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var action = 'mwu31bc';
export var actionButton = 'mwu31bd';
export var actionTextColor = 'var(--mwu31b3)';
export var card = 'mwu31b6';
export var cardBorderColor = 'var(--mwu31b2)';
export var cardColor = 'var(--mwu31b0)';
export var cardForeground = 'var(--mwu31b1)';
export var cardInner = 'mwu31b7';
export var closeButton = 'mwu31be';
export var closeIcon = 'mwu31bf';
export var closeIconColor = 'var(--mwu31b5)';
export var headAlignWrapper = 'mwu31b9';
export var header = 'mwu31b8';
export var icon = 'mwu31ba';
export var iconColor = 'var(--mwu31b4)';
export var main = 'mwu31bg';
export var title = 'mwu31bb';